// ARIAL FONT

@font-face {
    font-family: 'Arial';
    src:url("../fonts/Arial.ttf");
}

@font-face {
    font-family: 'Arial Bold';
    src:url("../fonts/Arial-bold.ttf");
}

// ITC AVANT GARDE FONT

@font-face {
	font-family: 'Avant Garde Bold';
	src: url("../fonts/avant-bold.woff");
}
@font-face {
	font-family: 'Avant Garde Demi';
	src: url("../fonts/avant-demibold.woff");
}
@font-face {
	font-family: 'Avant Garde Medium';
	src: url("../fonts/avant-medium.woff");
}
@font-face {
	font-family: 'Avant Garde Book';
	src: url("../fonts/avant-book.woff");
}
@font-face {
	font-family: 'Avant Garde Light';
	src: url("../fonts/avant-light.woff");
}


$avant-bold: 'Avant Garde Bold';
$avant-demi: 'Avant Garde Demi';
$avant-medium: 'Avant Garde Medium';
$avant-book: 'Avant Garde Book';
$avant-light: 'Avant Garde Light';

$body-font: 'Arial';
$body-font-bold: 'Arial Bold';

$color-blue: #00B1B1;
$color-grey: #50687B;
$color-lightblue: #E7F3F4;
$color-orange: #f07d22;
$color-green: #42b284;
$color-purple: #6f1e82;
$color-darkblue: #125fab;
$color-lightpurple: #f1e9f3;
$color-black: #000;
$color-white: #fff;

$color-primary: #661c4a;
$color-secondary: #978a37;
$color-white: #fff;
$color-black: #000;

p {
	color: $color-grey;
	font-size: 16px;
	@media(min-width: 768px){
		font-size: 16px;
	}
	@media(min-width: 992px){
		font-size: 18px;
	}
	@media(min-width: 1200px){
		font-size: 18px;
	}
}

:root {
	--font-small: 15px;

	@media(min-width:768px){
		--font-small:16px;
	}
	@media(min-width:992px){
		--font-small:17px;
	}
}

main {
	width: 100%;
	height: auto;
	position: relative;
	overflow: hidden;
}

body.__locked {
	overflow: hidden;
}

.header {
	width: 100%;
	height: auto;
	position: relative;
	max-width: 1200px;
	padding: 1.5rem 0;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;

	@media(min-width: 992px){
		padding: 2.5rem 0;
		height: 15vh;
	}
}

.header__logo {
	width: auto;
	height: 34px;
	display: block;

	@media(min-width: 992px){
		height: 55px;
	}
	@media(min-width: 1200px){
		height: 60px;
	}

	svg {
		width: 100%;
		height: 100%;
	}
}

.feature {
	width: 100%;
	height: auto;
	position: relative;
	margin-bottom: 35px;

	@media(min-width: 768px){
		margin-bottom: 1.5rem;
	}
}

.feature__wrapper {
	width: 100%;
	height: auto;
	position: relative;
	padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.feature__media {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.feature__toggle {
	position: absolute;
	top: 100%;
	right: 0;
	padding: 0.5rem 1rem;
	background-color: $color-blue;
	outline: none;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.3s ease;
	z-index: 2;
	opacity: 1;
	animation: pulse-animation 2s infinite;
	width: 100%;

	@media(min-width:768px){
		padding: 1rem 2rem;
		top: 0;
		width: auto;
	}

	&:hover,
	&:focus {
		background-color: darken($color-blue, 10%);
		color: #fff;
		text-decoration: none;
	}

	&.active {
		animation: none;
	}
}

  @keyframes pulse-animation {
	0% {
	  box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
	}
	100% {
	  box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
	}
  }

.feature__toggleText {
	color: #fff;
	font-family: $avant-demi;
	font-weight: 400;
	font-size: 12px;
	margin-right: 5px;
	position: relative;
	padding-top: 4px;

	@media(min-width:768px){
		font-size: 16px;
	}
	@media(min-width:992px){
		font-size: 20px;
	}
}

.feature__svgPlay {
	display: flex;
	justify-content: center;
	width: 15px;
	height: 15px;

	@media(min-width:768px){
		width: 20px;
		height: 20px;
	}
	&.hide {
		display: none;
	}
	svg {
		fill: #fff;
		position: relative;
		width: 100%;
		height: 100%;
	}
}
.feature__svgMute {
	display: flex;
	justify-content: flex-start;
	padding-right: 6px;
	width: 15px;
	height: 15px;

	@media(min-width:768px){
		width: 20px;
		height: 20px;
		padding-right: 8px;
	}
	&.hide {
		display: none;
	}
	svg {
		fill: #fff;
		position: relative;
		width: 100%;
		height: 100%;
	}
}

img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}


.intro {
	width: 100%;
	height: auto;
	position: relative;
	background-color: $color-white;

	&.__blue {
		background-color: $color-lightblue;
	}
}

.intro__wrapper {
	max-width: 1000px;
	width: 100%;
	height: auto;
	position: relative;
	margin: 0 auto;
	padding: 1.5rem 1.5rem 2.5rem 1.5rem;

	@media(min-width: 768px){
		padding: 3.5rem 1.5rem 3.5rem 1.5rem;
	}

	h2 {
		color: $color-grey;
		font-family: $avant-demi;
		display: block;
		margin-bottom: 1rem;

		@media(min-width: 992px){
			font-size: 47px;
		}

		strong {
			color: $color-blue;
		}

		&.leading {
			margin-bottom: 1.5rem;

			@media(min-width: 992px){
				margin-bottom: 2.5rem;
			}
		}
	}

	p {
		font-family: $avant-book;
		font-size: 18px;

		@media(min-width: 768px){
			font-size: 18px;
		}

		@media(min-width: 992px){
			font-size: 19px;
		}

		strong {
			font-family: $avant-medium;
		}

		&.large {
			font-size: 20px;

			@media(min-width: 768px){
				font-size: 23px;
			}

			@media(min-width: 992px){
				font-size: 26px;
			}
		}
	}
}

.intro__article {
	width: 100%;
	margin-bottom: 2.5rem;
	display: block;
	@media(min-width: 768px){
		display: flex;
		justify-content: space-between;
	}

	div.intro__article-image {
		display: block;
		width: 100%;
		height: 250px;
		position: relative;
		margin-bottom: 1.5rem;
		border-bottom-left-radius: 70px;
		overflow: hidden;

		@media(min-width: 768px){
			width: 48%;
			height: auto;
		}

		img {
			object-position: top center;
		}
	}
	div.intro__article-text {
		display: block;
		width: 100%;
		height: auto;
		position: relative;

		@media(min-width: 768px){
			width: 48%;
		}

		ul {
			padding: 0;
			margin-bottom: 2rem;
			li {
				font-family: $avant-book;
				color: $color-grey;
				position: relative;
				margin-bottom: 15px;
				padding-left: 15px;
				font-size: 18px;
				line-height: 1.15em;

				@media(min-width: 768px){
					font-size: 18px;
				}

				@media(min-width: 992px){
					font-size: 19px;
				}

				&::before {
					content: '';
					position: absolute;
					top: 8px;
					left: 0px;
					width: 7px;
					height: 7px;
					background-color: $color-grey;
					border-radius: 100%;
					vertical-align: middle;
				}

				&::marker {
					font-size: 0;
				}


			}
		}
	}
}

.video {
	width: 100%;
	height: auto;
	position: relative;
	margin-bottom: 2.5rem;
	@media(min-width: 768px){
		margin: 0;
	}
}
.video__play {
	position: absolute;
	top: 58%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 120px;
	height: 120px;
	border-radius: 100%;
	background-color: $color-blue;
	transition: all 0.3s ease;
	display: none;
	justify-content: center;
	align-items: center;

	@media(min-width: 768px){
		display: flex;
	}
	@media(min-width: 992px){
		width: 150px;
		height: 150px;
	}

	svg {
		width: 120px;
		height: 50px;
		padding-left: 5px;
		@media(min-width: 992px){
			width: 140px;
			height: 60px;
			padding-left: 10px;
		}
	}
}
.video__wrapper {
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
	padding: 0;
	position: relative;
	height: auto;

	@media(min-width: 768px){
		padding: 2.5rem 1.5rem;
	}
}
.video__item {
	position: relative;
	width: 100%;
	background-color: $color-white;
	cursor: pointer;
	padding-bottom: 43px;
	overflow: hidden;

	@media(min-width: 992px){
		padding-bottom: 0;
	}
}

.video__poster {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: calc(100% - 40px);
	display: block;

	@media(min-width: 992px){
		height: 447px;
		width: 101%;
	}

	&:hover,
	&:focus {
		.video__play {
			background-color: darken($color-blue, 5%);
		}
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: right top;

		&:first-of-type {
			display: block;

			@media(min-width: 768px){
				display: none;
			}
		}
		&:last-of-type {
			display: none;

			@media(min-width: 768px){
				display: block;
			}
		}
	}

	&.hide {
		display: none;
	}

	.video__poster-message {
		position: absolute;
		z-index: 3;
		top: 30px;
		right: 30px;

		@media(min-width: 992px){
			top: 60px;
			right: 60px;
		}

		h4 {
			color: $color-grey;
			font-family: $avant-book;
			font-size: 16px;
			@media(min-width: 768px){
				font-size:22px;
			}
			@media(min-width: 992px){
				font-size:26px;
			}

			strong {
				font-family: $avant-demi;
				font-size: 17px;
				@media(min-width: 768px){
					font-size:28px;
				}
				@media(min-width: 992px){
					font-size:32px;
				}
			}
		}
		p {
			font-family: $avant-demi;
			color: $color-blue;
			font-size: 16px;
			margin-top: 1.5rem;

			@media(min-width: 992px){
				font-size: 18px;
			}

			&.desktop {
				display: none;

				@media(min-width: 992px){
					display: block;
				}
			}
		}
	}
}

.video__message {
	display: block;
	position: absolute;
	top: calc(100% - 50px);
	right: 0;
	padding: 1rem 2.5rem;
	background-color: $color-blue;
	color: $color-white;
	font-family: $avant-demi;
	font-size: 18px;
	cursor: pointer;
	z-index: 3;
	line-height: 1em;
	width: 100%;
	text-align: center;

	@media(min-width: 992px){
		top: 0;
		width: 200px;
		display: none;
		padding: 1rem 1.7rem;
	}

	&.show {
		display: block;
	}
}

.video__media {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;

	@media(min-width: 992px){
		height: auto;
	}

	video {
		width: 100%;
		height: 100%;
	}
}

.imageblock {
	width: 100%;
	height: auto;
	position: relative;
}

.imageblock__media {
	width: 100%;
	height: 175px;
	position: relative;
	display: flex;
	align-items: flex-end;
	padding-bottom: 2.5rem;
	margin-bottom: 2.5rem;

	@media(min-width: 768px){
		height: 300px;
		margin-bottom: 3.5rem;
		padding-bottom: 3.5rem;
	}
	@media(min-width: 992px){
		height: 375px;
	}

	img {
		z-index: 1;
		position: relative;
	}
}

.imageblock__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-size: cover;
	background-position: center;


	@media(min-width: 992px){
		background-attachment: fixed;
	}
}

.imageblock__overlay {
	position: relative;
	z-index: 3;
	color: #fff;
	font-size: 32px;
	font-family: $avant-demi;
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
	opacity: 0.7;
	padding: 0 1.5rem;
	line-height: 1em;

	@media(min-width: 768px){
		font-size: 50px;
	}
	@media(min-width: 992px){
		font-size: 70px;
	}
	@media(min-width: 1200px){
		font-size: 80px;
	}
	@media(min-width: 1400px){
		font-size: 90px;
	}
	@media(min-width: 1600px){
		font-size: 95px;
	}
	@media(min-width: 1800px){
		font-size: 100px;
	}

	&.aos-animate {
		opacity: 0.7!important;
	}
}

.imageblock__text,
.imageblock__usp,
.imageblock__article {
	max-width: 1000px;
	padding: 0 1.5rem;
	position: relative;
	margin: 0 auto;

	h4, p {
		font-family: $avant-book;
		color: $color-grey;
		margin-bottom: 1rem;
		display: block;

		strong {
			font-family: $avant-demi;
		}
	}

	h4 {
		font-size: 24px;
		line-height: 1.3em;
	}
}

.imageblock__text {
	margin-bottom: 1.5rem;
	display: block;

	@media(min-width: 992px){
		margin-bottom: 2.5rem;
	}
}

.imageblock__usp {
	margin-bottom: 2.5rem;
	display: block;
	@media(min-width: 768px){
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 2rem;
	}
}

.imageblock__usp-item {
	width: 100%;

	div:first-of-type {
		display: block;
		width: 100%;
		height: 250px;
		position: relative;
		margin-bottom: 1.5rem;
		border-bottom-left-radius: 50px;
		overflow: hidden;

		img {
			object-position: top;
		}
	}
	div:last-of-type {
		display: block;
		width: 100%;
		height: auto;
		position: relative;
	}
}

.imageblock__article {
	width: 100%;
	margin-bottom: 2.5rem;
	display: block;
	@media(min-width: 768px){
		display: flex;
		justify-content: space-between;
	}

	div.article-image {
		display: block;
		width: 100%;
		height: 230px;
		position: relative;
		margin-bottom: 1.5rem;
		border-bottom-left-radius: 70px;
		overflow: hidden;

		@media(min-width: 768px){
			width: 48%;
			height: auto;
		}

		img {
			object-position: top;
		}
	}
	div.article-text {
		display: block;
		width: 100%;
		height: auto;
		position: relative;

		@media(min-width: 768px){
			width: 48%;
		}

		ul {
			padding: 0;
			margin-bottom: 2rem;
			li {
				font-family: 'Arial';
				font-size: 18px;
				color: $color-grey;
				position: relative;
				margin-bottom: 15px;

				span {
					display: inline-block;
					position: relative;
					margin-right: 15px;
					margin-bottom: 2px;
					width: 8px;
					height: 8px;
					background-color: $color-grey;
					border-radius: 100%;
					vertical-align: middle;
				}

				&::marker {
					font-size: 0;
				}

				@media(min-width: 768px){
					font-size: 18px;
				}
				@media(min-width: 992px){
					font-size: 21px;
				}
				@media(min-width: 1200px){
					font-size: 24px;
				}
				@media(min-width: 1600px){
					font-size: 28px;
				}
				@media(min-width: 1800px){
					font-size: 38px;
				}
			}
		}
	}
}

.footer {
	width: 100%;
	height: auto;
	position: relative;
	background-color: $color-lightblue;
}

.footer__wrapper {
	max-width: 1000px;
	width: 100%;
	height: auto;
	position: relative;
	margin: 0 auto;
	padding: 2.5rem 1.5rem 4.5rem 1.5rem;

	span {
		color: $color-grey;
		font-family: $avant-demi;
		display: block;
		font-size: 24px;
		line-height: 1.2em;

		@media(min-width: 992px){
			font-size: 48px;
			width: 100%;
		}

		strong {
			color: $color-blue;
		}

		a {
			color: $color-grey;
			font-family: $avant-demi;
			transition: all 0.3s ease;
			text-decoration: none;

			&:hover,
			&:focus {
				color: $color-blue;
			}
		}
	}
}